import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for Haemonetics 5+ Cell Saver Device",
    sections: [
        // {...employeeinfo},
        {
            title: "Device Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs a visual inspection of the device for cleanliness and functionality.",
                "Assembles and connects all components (e.g., reservoir, tubing, filters).",
                "Verifies proper power connection and device startup sequence.",
                "Ensures the selection of appropriate collection and reinfusion settings.",
                "Prepares anticoagulant solution as per the manufacturer's guidelines."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Operation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Operates the device and monitors blood collection and processing parameters.",
                "Demonstrates the ability to adjust settings based on surgical requirements.",
                "Monitors quality of processed blood (e.g., hematocrit levels, volume).",
                "Responds to alarms and troubleshooting during operation.",
                "Maintains appropriate anticoagulation flow during the procedure.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Safety and Infection Control",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Adheres to aseptic techniques during setup and operation.",
                "Follows guidelines for disposal of waste and single-use components.",
                "Ensures safe handling of blood products to avoid contamination.",
                "Maintains compliance with infection control policies and procedures.",
                "Verifies all safety features of the device are operational."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Cleaning and Maintenance",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Performs routine cleaning and disinfection of the device per manufacturer instructions.",
                "Logs all cleaning and maintenance activities in the designated records.",
                "Replaces filters and consumables at specified intervals.",
                "Identifies and reports any mechanical or functional issues for repair.",
                "Demonstrates proper use of manufacturer-approved cleaning agents."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Documentation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Records pre-use checks and operational settings.",
                "Logs all maintenance, cleaning, and disinfection tasks accurately.",
                "Documents any deviations or alarms encountered during operation.",
                "Maintains accurate records of processed and reinfused blood volumes.",
                "Ensures documentation compliance with facility and regulatory requirements."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields:getSignatures(FillableBy.Both)
        },
    ],
}